<template>
  <div class="card">
    <div class="card-body pb-0 pt-2">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">Demographic View</h4>
        <div class="d-flex overall-count pb-2">
          <div class="mr-2">Overall Count</div>
          <Switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></Switches>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card-body">
          <!-- <div class="d-flex justify-content-between align-items-center"> -->
          <!-- <h4 class="card-title">Countries</h4> -->
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title m-0">Countries</h4>
            <JsonExcel
              class="btn p-0"
              :data="formattedCountries"
              name="live_events_countries.csv"
              type="csv">
              <i class="mdi mdi-download"></i>
              Download
            </JsonExcel>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPageCountry"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              :items="formattedCountries"
              :fields="countryfields"
              responsive="sm"
              :per-page="perPageCountry"
              :current-page="currentPageCountry"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(state)="row">
                <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_pa ginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCountry"
                    :total-rows="totalRowsCountry"
                    :per-page="perPageCountry"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-body">
          <!-- <div class="d-flex justify-content-between align-items-center"> -->
          <!-- <h4 class="card-title">States</h4> -->
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title m-0">States</h4>
            <JsonExcel
              class="btn p-0"
              :data="formattedStates"
              name="live_events_states.csv"
              type="csv">
              <i class="mdi mdi-download"></i>
              Download
            </JsonExcel>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPageState"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              :items="states"
              :fields="statefields"
              responsive="sm"
              :per-page="perPageState"
              :current-page="currentPageState"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(state)="row">
                <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_pa ginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageState"
                    :total-rows="totalRowsState"
                    :per-page="perPageState"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-body">
          <!-- <div class="d-flex justify-content-between align-items-center"> -->
          <!-- <h4 class="card-title">Cities</h4> -->
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title m-0">Cities</h4>
            <JsonExcel
              class="btn p-0"
              :data="formattedCities"
              name="live_events_cities.csv"
              type="csv">
              <i class="mdi mdi-download"></i>
              Download
            </JsonExcel>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPageCity"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              :items="cities"
              :fields="cityfields"
              responsive="sm"
              :per-page="perPageCity"
              :current-page="currentPageCity"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(city)="row">
                <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_pa ginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPageCity"
                    :total-rows="totalRowsCity"
                    :per-page="perPageCity"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import Switches from "vue-switches";
import JsonExcel from "vue-json-excel";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    Switches,
    JsonExcel
  },
  data() {
    return {
      countries: [],
      cities: [],
      states: [],
      overallCount: false,
      totalRowsCountry: 1,
      totalRowsCity: 1,
      totalRowsState: 1,
      currentPageCountry: 1,
      currentPageCity: 1,
      currentPageState: 1,
      perPageCountry: 5,
      perPageCity: 5,
      perPageState: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_views",
      sortDesc: true,
      countryfields: [
        { key: "country", sortable: true, label: "Country" },
        { key: "total_registrations", sortable: true, label: "Registrations" },
        { key: "total_visitors", sortable: true, label: "Audience" },
      ],
      statefields: [
        { key: "state", sortable: true, label: "State" },
        { key: "total_registrations", sortable: true, label: "Registrations" },
        { key: "total_visitors", sortable: true, label: "Audience" },
      ],
      cityfields: [
        { key: "city", sortable: true, label: "City" },
        { key: "total_registrations", sortable: true, label: "Registrations" },
        { key: "total_visitors", sortable: true, label: "Audience" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getData();
      },
    },
    overallCount() {
      this.getData();
    },
  },
  computed: {
    /* Total no. of records */
    rows() {
      return this.data.length;
    },
    formattedCountries(){
      return this.countries.map(c => {
        if(c.country == '' || c.country == null){
          c.country = 'Unknown';
        }
        return c
      });
    },
    formattedStates(){
      return this.states.map(c => {
        if(c.state == '' || c.state == null){
          c.state = 'Unknown';
        }
        return c
      });
    },
    formattedCities(){
      return this.cities.map(c => {
        if(c.city == '' || c.city == null){
          c.city = 'Unknown';
        }
        return c
      });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      this.countries = [];
      this.cities = [];
      this.states = [];
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/liveEvents/demographics?overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.countries = response.data.demographics;
          this.cities = response.data.demographics;
          this.states = response.data.demographics;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/liveEvents/demographics",
            {
              params: searchfiltersParams,
            }
          );
          this.countries = response.data.demographics;
          this.cities = response.data.demographics;
          this.states = response.data.demographics;
        }
        const compressedCountries = this.countries.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.country === c.country &&
              (c.country != null || c.country != "null" || c.country != "")
          );
          if (dateIndex !== -1) {
            a[dateIndex].total_registrations += parseInt(c.total_registrations);
            a[dateIndex].total_visitors += parseInt(c.total_visitors);
          } else {
            a.push({
              country: c.country,
              total_registrations: c.total_registrations,
              total_visitors: c.total_visitors,
            });
          }
          return a;
        }, []);
        compressedCountries.sort((a, b) => {
          if (
            a.total_registrations > b.total_registrations
          ) {
            return -1;
          }
          if (
            a.total_registrations > b.total_registrations
          ) {
            return 1;
          }
          return 0;
        });
        this.countries = compressedCountries;
        this.totalRowsCountry = this.countries.length;

        const compressedStates = this.states.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.state === c.state &&
              (c.state != null || c.state != "null" || c.state != "")
          );
          if (dateIndex !== -1) {
            a[dateIndex].total_registrations += parseInt(c.total_registrations);
            a[dateIndex].total_visitors += parseInt(c.total_visitors);
          } else {
            a.push({
              state: c.state,
              total_registrations: c.total_registrations,
              total_visitors: c.total_visitors,
            });
          }
          return a;
        }, []);
        compressedStates.sort((a, b) => {
          if (
            a.total_registrations > b.total_registrations
          ) {
            return -1;
          }
          if (
            a.total_registrations < b.total_registrations
          ) {
            return 1;
          }
          return 0;
        });
        this.states = compressedStates;
        this.totalRowsState = this.states.length;

        const compressedCities = this.cities.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.city === c.city &&
              (c.city != null || c.city != "null" || c.city != "")
          );
          if (dateIndex !== -1) {
            a[dateIndex].total_registrations += parseInt(c.total_registrations);
            a[dateIndex].total_visitors += parseInt(c.total_visitors);
          } else {
            a.push({
              city: c.city,
              total_registrations: c.total_registrations,
              total_visitors: c.total_visitors,
            });
          }
          return a;
        }, []);
        compressedCities.sort((a, b) => {
          if (
            a.total_registrations > b.total_registrations
          ) {
            return -1;
          }
          if (
            a.total_registrations < b.total_registrations
          ) {
            return 1;
          }
          return 0;
        });
        this.cities = compressedCities;
        this.totalRowsCity = this.cities.length;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
