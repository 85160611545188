<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">Registrations</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></switches>
        </div>
      </div>
      <!-- Donut chart -->
      <VueApexCharts
        class="apex-charts"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Switches from "vue-switches";
import filterMixins from "../../../mixins/filterData";
import _ from "lodash";

export default {
  props: ["filters", "countData", "totalCountData"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    Switches,
  },
  data() {
    return {
      title: "Live Registrations",
      overallCount: false,
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
          type: "donut",
          width: 500,
        },
        labels: ["Pre Live Event", "During Live Event", "Post Live Event"],
        colors: ["#52BE80", "#fcb92c", "#5664D2"],
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    countData: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    overallCount() {
      this.loadData();
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    async loadData() {
      try {
        if (this.overallCount == false) {
          const data = _.cloneDeep(this.countData);
          this.series = [
            data.total_registrations_pre_event,
            data.total_registrations_during_event,
            data.total_registrations_post_event,
          ];
          this.key += 1;
        } else {
          const data = _.cloneDeep(this.totalCountData);
          this.series = [
            data.total_registrations_pre_event,
            data.total_registrations_during_event,
            data.total_registrations_post_event,
          ];
          this.key += 1;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
