<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title">Top 10 Specialities Audience</h4>
        <div class="d-flex overall-count">
          <div class="mr-2">Overall Count</div>
          <Switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="m-0"
          ></Switches>
        </div>
      </div>
      <!-- Bar Chart -->
      <VueApexCharts
        height="auto"
        type="bar"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import Switches from "vue-switches";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    Switches,
  },
  data() {
    return {
      title: "Live Registrations",
      TopSpecialityCount: [],
      series: [
        {
          name: "Audience",
          data: [],
        },
        {
          name: "Not Attended",
          data: [],
        },
      ],
      overallCount: false,
      chartOptions: {
        chart: {
          stacked: true,
          // stackType: "100%",
          foreColor: "#fff",
          toolbar: {
            show: true,
          },
        },
        labels: [""],
        // stroke: {
        //   width: [3],
        //   curve: "smooth",
        // },
        colors: ["#1cbb8c", "#5664d2"],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        noData: {
          text: "Loading...",
        },
        grid: {
          borderColor: "#5664d2",
        },
        xaxis: {
          categories: [],
        },
      },
      key: 0,
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getTopSpecialityCount();
      },
    },
    overallCount() {
      this.getTopSpecialityCount();
    },
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    async getTopSpecialityCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        let response = {};
        if (this.overallCount == true) {
          response = await this.$http.get(
            appConfig.api_base_url + "/liveEvents/specialities?overall=true",
            {
              params: searchfiltersParams,
            }
          );
        } else {
          response = await this.$http.get(
            appConfig.api_base_url + "/liveEvents/specialities",
            {
              params: searchfiltersParams,
            }
          );
        }
        this.TopSpecialityCount = response.data.specialities;
        this.series[0].data = [];
        this.series[1].data = [];
        this.chartOptions.xaxis.categories = [];
        const compressed = this.TopSpecialityCount.reduce((a, c) => {
          if (
            c.speciality == null ||
            c.speciality == "Unknown" ||
            c.speciality == "null" ||
            c.speciality == ""
          ) {
            c.speciality = "Others";
          }
          const dateIndex = a.findIndex(
            (o) =>
              o.speciality === c.speciality &&
              (c.speciality != null || c.speciality != "")
          );
          if (dateIndex !== -1) {
            a[dateIndex].total_visitors += c.total_visitors;
            a[dateIndex].total_registrations += c.total_registrations;
          } else {
            a.push({
              speciality: c.speciality,
              total_visitors: c.total_visitors,
              total_registrations: c.total_registrations - c.total_visitors,
            });
          }
          return a;
        }, []);
        compressed.sort(function (a, b) {
          return b.total_visitors - a.total_visitors;
        });
        compressed.forEach((f, i) => {
          if (i < 10) {
            this.chartOptions.xaxis.categories.push(f);
            this.series[0].data.push(f);
            this.series[1].data.push(f);
          }
        });
        this.chartOptions.xaxis.categories =
          this.chartOptions.xaxis.categories.map((obj) => obj.speciality);
        this.series[0].data = this.series[0].data.map(
          (obj) => obj.total_visitors
        );
        this.series[1].data = this.series[1].data.map(
          (obj) => obj.total_registrations
        );
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getTopSpecialityCount();
  },
};
</script>
