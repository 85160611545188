<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div class="row">
      <div class="col-lg-4">
        <PieLiveEventRegister
          :filters="filters"
          :countData="countData"
          :totalCountData="totalCountData"
        ></PieLiveEventRegister>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <!-- <div class="col-lg-12">
                    <LiveEventDetails :filters="filters"></LiveEventDetails>
                </div> -->
          <div class="col-lg-6">
            <PrimeUsersRegister
              :filters="filters"
              :countData="countData"
              :totalCountData="totalCountData"
            ></PrimeUsersRegister>
          </div>
          <!-- <div class="col-lg-2">
                <RetendedUsers :filters="filters"></RetendedUsers>
            </div> -->
          <div class="col-lg-6">
            <TopViewers
              :filters="filters"
              :countData="countData"
              :totalCountData="totalCountData"
            ></TopViewers>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <RegisterVisitorRatio
          :filters="filters"
          :countData="countData"
          :totalCountData="totalCountData"
        ></RegisterVisitorRatio>
      </div>
      <div class="col-lg-7">
        <SpecialityWiseCount :filters="filters"></SpecialityWiseCount>
      </div>
    </div>
    <div class="col">
      <DemographicView :filters="filters"></DemographicView>
    </div>
    <!-- <div class="row">
        <div class="col-lg-4">
            <CountryWiseCount :filters="filters"></CountryWiseCount>
        </div>
        <div class="col-lg-4">
            <StateWiseCount :filters="filters"></StateWiseCount>
        </div>
        <div class="col-lg-4">
            <CityWiseCount :filters="filters"></CityWiseCount>
        </div>
    </div> -->
  </Layout>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "../live-events/filters.vue";
import PrimeUsersRegister from "./PrimeUsersRegister.vue";
// import RetendedUsers from "./RetendedUser.vue";
import TopViewers from "./TopViewers.vue";
import PieLiveEventRegister from "./PieLiveEventRegister.vue";
import RegisterVisitorRatio from "./RegisterVisitorRatio.vue";
// import CountryWiseCount from "./CountryWiseCount.vue";
// import StateWiseCount from "./StateWiseCount.vue";
// import CityWiseCount from "./CityWiseCount.vue";
import SpecialityWiseCount from "./SpecialityWiseCount.vue";
import DemographicView from "./DemographicView.vue";

/**
 * Dashboard component
 */
export default {
  mixins: [filterMixins],
  components: {
    Layout,
    PageHeader,
    Filters,
    PrimeUsersRegister,
    // RetendedUsers,
    TopViewers,
    PieLiveEventRegister,
    RegisterVisitorRatio,
    // CountryWiseCount,
    // StateWiseCount,
    // CityWiseCount,
    SpecialityWiseCount,
    DemographicView,
  },
  data() {
    return {
      title: "Live Events",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Live Events",
          active: true,
        },
      ],
      filters: {},
      reload: 0,
      loading: true,
      countData: {},
      totalCountData: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      this.getData();
      // this.loading = false;
    },
    async getData() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/liveEvents/count",
          {
            params: searchfiltersParams,
          }
        );
        this.countData = response.data.count[0];
        this.totalCountData = response.data.total_count[0];
      } catch (err) {
        console.log(err);
      }
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
};
</script>
